@import '../../abstracts/vars';
@import '../../abstracts/mixins';
@import "../../base/fontawesome/mixins";
@import "../../base/fontawesome/variables";

//Drop Down Navigation Common Part
.navigation.navigation-main, .navigation.global-nav {
  position: relative;
    @include max-screen(md) {
        position: absolute;
        bottom: 0;
        z-index: 2;
        text-align: center;

    }

    a {
         @include max-screen(md) {
           padding: 1em
        }
     }

  .component-content {
    position: relative;
  }

  .component-content > nav {
    position: relative;
  }

  .rel-level1 {
    display: inline-block;

    &.active {
      > span {
        display: block;
        padding: 10px 20px;
        color: $text-white;
      }
    }

    > .navigation-title {
      > a {
        &:hover {
          border-color: $nav-border-root;
        }
      }
    } 

    &:last-child > .navigation-title > a {
      margin-right: 0;
    }

    > ul {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: -2px;
      margin-left: 0;
      border-top: 2px solid $nav-border-submenu;
      border-bottom: 1px solid $nav-submenu-item-border;
      border-left: 1px solid $nav-submenu-item-border;
      background: $nav-bg-submenu;
      overflow: hidden;
      z-index: 10;
      padding: 0 calc(2.4em) 0
    }

    &.show {
      > a {
        & + ul {
          display: block !important;
        }
      }

      > ul {
        display: block !important;
        min-width: 60px;
      }
    }

    &.active {
      a:before{
        width:100%;
        border-bottom: 2px solid $text-white;
      }
    }
  }

  .rel-level2 {
    &:hover,
    &:focus {
      transition: background 0.2s ease-in;
      background: $nav-bg-submenu-active;
    }

    &.submenu {
      box-sizing: border-box;

      &.navigation-image {
        text-align: center;

        > a,
        .field-navigationtext {
          text-align: left;
        }
      }
    }
  } // Additional styles fot drop down horizontal navigation

  &.navigation-main-horizontal {
    .rel-level1 {
      &.show {
        > a {
          & + ul {
            display: inline-flex !important;
            flex-wrap: wrap;
          }
        }

        > ul {
          display: inline-flex !important;
          flex-wrap: wrap;
        }
      }

      > ul {
        left: 0;
        width: 100%;
        clear: both;

        li {
            height: auto;
        }
      }
    }
  } // Additional styles fot drop down vertical navigation

  &.navigation-main-vertical {
    .rel-level1 > ul {
      left: auto;
      width: auto;
      padding-left: 0;
      border-right: 1px solid $nav-submenu-item-border;

      > li {
        padding: 5px 10px;
      }
    }
  }
}
