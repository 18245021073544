/**
 * Custom Sitemap Navigation Component
 * @author Charles Harwood
 */
&.navigation--sitemap {
  @include font(20px);
  padding: 0;
  
  nav {
    // Outer wrapping list.
    > ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
    }

    // All levels.
    li {
      > .navigation-title > a {
        display: inline-block;
        padding: em(12px 0, 16px);
        @include font(16px, 16px);
      }
    }

    // First level.
    .level1 {
      flex-basis: 25%;
      max-width: 25%;

      ul {
        display: block;
      }

      @include max-screen(md) {
        flex-basis: 50%;
        max-width: 50%;
      }

      @include max-screen(sm) {
        flex-basis: 100%;
        max-width: 100%;
      }

      > .navigation-title > a {
        @include font(26px);
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }



    }

    // Second level.
    .level2 {

      > .navigation-title > a {
        @include font(18px);
        font-weight: 400;
      }
    }
  }
}
