@import "abstracts/mixins";
@import "abstracts/vars";

.navigation {
	background: $nav-bg;
	padding: 0.6em 4em;

	@include max-screen(md) {
		padding: 0.6em 1em;
	}
	@import 'variants/navigation/sitemap';
}

.navigation.navigation-main.site-nav,
.navigation.navigation-main.site-nav .component-content,
.navigation.navigation-main.site-nav .component-content>nav {
	position: static;
}

/*
//This is here. But with a backstory:
.container {
	max-width: 100%;
}

In the beginning. SXA labelled all of their components with class container, even if they were full-width.
Instead of adding container-full class to navigation item, this class was added to  navigation to override the widths.
*/

.container {
	max-width: 100%;
}


header {

	position: relative;
	margin: 0 -15px;
	z-index: 10;

	@include max-screen(xxl) {
		font-size: 0.669vw;
	}

	// @include max-screen(lg){
	// 	font-size: 10px;
	// }

	@include max-screen(md) {
		font-size: 10px;
	}

	.container {
		max-width: 100%;
	}

	// .row {
	//	flex-wrap: nowrap;
	// }

	.component {
		margin-top: 0;
	}

	.nav-wrapper {

		background-color: $cl__black;
		z-index: 9;
		transition: top 2s ease, background-color 0.3s ease;
		position: relative;
		
		&.sticky {
			position: fixed;
			width: calc(100% - 48px);

			top: 0px;

			@include max-screen(md) {
				width: 100%
			}
		}

		&.homesticky {
			@include max-screen(sm) {
				background: #000;
			}

			.logo {
				display: block;
			}
		}

		.component-content {
			display: flex;

			@include max-screen(md) {
				display: block;
			}
		}

		.image {
			flex-basis: 126px;
			padding: 2.1em 0 1.1em 4.6em;
			z-index: 10;
			position: relative;

			@include max-screen(md) {
				padding: 2.2em 0 1.4em 29px;
			}
		}

		@include max-screen(md) {
			justify-content: space-between;
			height: 75px;
			// overflow: hidden;
		}

		.mirvac-logo {
			padding: 2.2em 0 0 3.7em;

			>.component-content a {
				position: relative;

				&:before {
					content: '';
					border-left: 1px solid #fff;
					height: 18px;
					position: absolute;
					left: -37px;
					top: 2px;
					opacity: 0.5;
				}

				@include max-screen(md) {
					display: none;
				}
			}
		}
	}

	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11,
	.col-12,
	.col,
	.col-auto,
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm,
	.col-sm-auto,
	.col-md-1,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md,
	.col-md-auto,
	.col-lg-1,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg,
	.col-lg-auto,
	.col-xl-1,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl,
	.col-xl-auto {
		position: static;
	}

	.logo img {
		width: 64px
	}

	.mirvac-logo {
		img {
			width: 45px;
		}
	}
}

.home {
	.nav-wrapper {
		@include max-screen(sm) {

			background: transparent;
		}
	}

	.nav-wrapper .logo {
		@include max-screen(sm) {
			display: none;
		}
	}
}

header .component.search-box.horizontal {
	display: none;
}

body.nav-is-sticky {
	padding-top: 7.2em;
}

header .component.navigation {

	padding: 1.4em 1.8em 2.5em 0;

	&.site-nav {

		max-width: 100%;
		flex-basis: 39%;
		flex-grow: 1;
		padding-left: 2.6em;
		padding-right: 0;

		@include max-screen(xl) {
			padding-left: 0;
		}

		nav ul li {
			@include max-screen(md) {
				display: none;
			}
		}

		@include max-screen(md) {
			&.navigation-main {
				display: none;
			}
		}
	}

	&.navigation-global {

		max-width: 100%;
		flex-basis: 23%;
		flex-grow: 0;

		>.component-content {
			justify-content: flex-end;
			padding-right: 1.1em;
		}

		@include max-screen(md) {
			position: relative;
			padding: 0;
			margin-top: -52px
		}

		nav {
			ul {
				justify-content: flex-end;

				li {
					border-left: none;
					padding: 0 2.4em;

					@include max-screen(md) {
						display: none;

					}

				}
			}
		}

		.search-toggle {

			a {
				display: inline-block;
				text-indent: -9999px;
				background-image: url(../images/icons/icon-nav-search.svg);
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: contain;
				width: 1.3em;
				height: 1.3em !important;
				margin-top: -0.2em;

				@include max-screen(md) {
					background: url(../images/icons/icon-nav-search.svg);
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 20px;
					position: absolute !important;
					top: 0px;
					right: 69px;
					padding: 7px
				}

				// &:after {
				// 	content: '';
				// 	position: absolute;
				// 	width: 50px;
				// 	height: 50px;
				// 	top: 50%;
				// 	left: 50%;
				// 	transform: translate(-50%, -50%);
				// }

			}


			&.is-open {
				a {
					background-image: url(../images/icons/icon-close--white.svg);
				}

				.component.search-box.horizontal {
					display: inline-block;
				}
			}

			.component.search-box.horizontal {
				position: absolute;
				top: 2.8em;
				z-index: 1;
				right: 0;
				z-index: 200;
				background: #f9f9f9;
				font-size: 2em;
				width: 54rem;
				max-width: calc(100vw - 6rem);

				@include min-screen(md) {
					@include max-screen(lg) {
						top: 3.2em;
					}
				}

				@include max-screen(md) {
					max-width: 100%;
				}

				input {
					max-height: 6.3rem;
					padding: 1.1em 1.8em 1.1em;
					border: 0;
					background-color: #f9f9f9 !important;
					color: #55565b;
					font-size: 1em;
					line-height: 1.4em;
					box-shadow: none;

					@include max-screen(md) {
						position: absolute;
						left: 0;
						right: 0;
					}
				}

				button {
					display: block;
					position: absolute;
					top: 50%;
					right: 0.9em;
					width: 2.4rem;
					height: 2.4rem;
					padding: 0;
					transform: translateY(-50%);
					border: 0;
					background: url(../images/icons/icon-nav-search--grey.svg) !important;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					text-indent: -9999px;
					cursor: pointer;
					appearance: none;
				}

				@include max-screen(md) {
					position: absolute;
					top: 44px;
					width: 100%;
					right: 0;
					left: 0;
				}

				label {
					@include max-screen(md) {
						display: none;
					}
				}
			}

			.tt-dropdown-menu {
				display: none !important;
			}

			//CONN search toggle on mobile
			@include max-screen(md) {
				display: block;
				position: relative;

			}

		}
	}



	nav {

		ul {
			margin-top: 1.3em;

			li {
				vertical-align: middle;
				flex-grow: 0;
				text-align: center;
				border-left: 1px solid rgba(255, 255, 255, 0.5);
				padding: 0 2.4em;
				height: 1.8em;

				@include min-screen(xxl) {
					//	padding: 0 3.8em; //original design, removed due to longer content CONN
				}

				//	padding-top: 1.3em;

				a+a {
					display: none;
				}

				.meganav-content {
					display: none;
				}
			}

			.is-open>a {
				&:after {
					content: '';
					display: inline-block;
					width: 1em;
					height: 1em;
					background-image: url(../images/icons/icon-close--white.svg);
					font-size: 0.8em;
					margin-left: 1.2em;
					background-repeat: no-repeat;
				}
			}
			
			.field-navigationimage {
				height:100%;
				background-size: cover;
				opacity: 0.4;
			}

			.level1>a,
			.level1>.field-navigationtitle>a {
				color: #FFFFFF;
				//font-family: $Oswald;
				font-size: 1.6em;
				font-weight: 500;
				line-height: 1;
				//text-transform: uppercase;
				vertical-align: bottom;
				display: inline-block;
				height: 25px;
				position: relative;
				white-space: nowrap;

				&:before {
					content: '';
					width: 0%;
					height: 2px;
					background: white;
					position: absolute;
					top: 100%;
					left: 0;
					transition: width 0.5s ease;
				}

				&:hover {
					text-decoration: none;

					&:before {
						@include min-screen(lg) {
							width: 100%;
						}
					}
				}
			}



			.logo-home {
				//	padding-top: 1.1em;
				max-width: 100px;
				border: 0;
				padding: 0;

				a {

					padding: 0;
					margin-right: 1.5em;
					text-align: left;


					img {
						max-width: 75px;
						width: 75px;
						margin-top: -0.5em;
					}

					+a {
						display: none;
					}
				}


				+li {
					border-left: 1px solid #fff;

					a {}
				}
			}

			.last {
				a {
					border-right: 0;
				}
			}


			li.is-open {
				>ul {
					display: flex;
				}
			}

			ul {
				margin-top: 0;
			}
		}
	}

	&.side-nav {
		.mirvac-logo {
			margin-top: -0.5em;
			border: 0;

			a {
				height: 25px;
				width: 45px;
				display: block;
				background: url(../images/icons/logo-mirvac.svg);
				text-indent: -9999px;
				display: inline-block;
			}
		}

		li:last-of-type {
			padding-right: 0;
			border-right: 0;
		}
	}

	&.site-nav {
		.dropdown {
			display: none;
			position: absolute;
			right: 0em;
			left: 0em;
			height: calc(100vh - 9.4em);
			top: 100%;
			z-index: 100;
			overflow: hidden;
			padding-bottom: 20px;
			background: #fff;


			.cookie-message-open & {
				top: 100%;
				height: calc(100vh - 10.6em);
			}

			@include min-screen(xxl) {
				//	height: calc(100vh - 170px);
			}

			@media (max-height: 800px) {
				font-size: 1vh;
			}

			&.is-active {
				display: flex;
			}

			>ul>li {
				margin-bottom: 3em;

				>a {
					font-size: 3.4em;
					font-family: $Frank;
				}
			}

			+ul.clearfix {
				display: none;
			}


			.image-text {
				flex-basis: 32.833333%;
				position: relative;
				//transform: translateX(-100%);
				//transition: transform 0.5s ease;
				background: black;
				flex-grow: 0;
				flex-shrink: 0;

				.field-navigationtext {
					height: 100%;
					position: absolute;
					z-index: 2;
					top: 0;
				}

				img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
					left: 0;
					opacity: 0.4;

					.no-object-fit & {
						width: auto;
						max-width: inherit;
						min-width: 100%;
					}
				}

				.content {
					display: flex;
					padding: 2.5em 2.5em;
					text-align: left;
					position: relative;
					flex-direction: column;
					justify-content: center;
					height: 100%;

					h3 {
						font-family: $Oswald;
						font-size: em(52px, 20px);
						text-transform: uppercase;
						margin-top: 0;
						margin-bottom: 0.5em;
					}

					.btn.btn-link {
						font-size: 16px;
					}
				}
			}

			>ul.clearfix {
				background: white;
				margin: 0;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				justify-content: center;
				align-items: start;
				padding: 2.5em 2.5em 2.5em;
				//transform: translateX(100%);
				//transition: transform 0.5s ease-out;
				min-width: 350px; //Conn. ie hack



				li {
					width: 100%;
					text-align: left;
					height: auto;
					margin-bottom: 3.1vh;


					&:hover,
					&:focus,
					&:active {
						background: none;
					}

					a {
						color: $nav-black;
					}

					&.level2 {
						font-family: $FrankRuhlLibre;
						font-size: em(3.5em, 0.85em);
						font-weight: 500;

					}

					&.level3 {
						font-family: $Roboto;
						font-size: em(1.6em, 2.72em);
						font-weight: 400;
					}
				}

				@media(max-width: 1600px) {
					li.level2 {
						font-size: 2.4vw;
					}
				}


				//Sub-sub nav
				ul.clearfix {
					display: flex;
					flex-direction: row;
					margin-top: 30px;

					li {

						border-right: 1px solid $cl--alto;
						line-height: 1em;
						width: auto;
						//max-width: none;

						&:first-of-type {
							padding-left: 0;
						}

						&:last-of-type {
							border-right: 0;
						}
					}

					a {
						font-size: em(1.6em, 2em);
						color: $cl__charcoal;
						font-family: $Roboto;
						text-transform: none;
					}
				}
			}

			&.animate {

				.image-text,
				>ul.clearfix {
					transform: translateX(0);
				}
			}
		}
	}

	&.mmenu-cover {
		background-color: #fff;
		background-image: url("/-/media/Project/Mirvac/OandI/200-George/Icons/logo-200-george-footer.svg?la=en&hash=8FA0F154C3C73EA6AB7C67031866D52C2BCFEFB8");
		background-position: 11px 14px;
		background-repeat: no-repeat;
		background-size: 65px;
	}
}



body.dropdown-open {
	overflow: hidden;

	#wrapper {
		>footer {
			// position: fixed;
			// bottom: 0;
			// left: 0;
			// right: 0;
			// background: white;
		}
	}
}

.component.navigation,
.component.link-list {
	nav {

		ul {
			display: flex;

			@include max-screen(md) {
				display: block;
			}
		}
	}
}



@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/OandI Base Site Theme/sass/styles/navigation/navigation-fat.scss";
@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/OandI Base Site Theme/sass/styles/navigation/navigation-main-horizontal-vertical.scss";
@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/OandI Base Site Theme/sass/styles/navigation/navigation-mobile.scss";
@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/OandI Base Site Theme/sass/styles/navigation/navigation-sidebar.scss";


