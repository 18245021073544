@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.navigation {
  background: transparent;
  padding: 0.6em 4em;
  /**
 * Custom Sitemap Navigation Component
 * @author Charles Harwood
 */
}

@media (max-width: 991px) {
  .navigation {
    padding: 0.6em 1em;
  }
}

.navigation.navigation--sitemap {
  font-size: 1.25em;
  padding: 0;
}

.navigation.navigation--sitemap nav > ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.navigation.navigation--sitemap nav li > .navigation-title > a {
  display: inline-block;
  padding: 0.75em 0em;
  font-size: 1em;
  line-height: 1em;
}

.navigation.navigation--sitemap nav .level1 {
  flex-basis: 25%;
  max-width: 25%;
}

.navigation.navigation--sitemap nav .level1 ul {
  display: block;
}

@media (max-width: 991px) {
  .navigation.navigation--sitemap nav .level1 {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .navigation.navigation--sitemap nav .level1 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.navigation.navigation--sitemap nav .level1 > .navigation-title > a {
  font-size: 1.625em;
  font-weight: 700;
}

.navigation.navigation--sitemap nav .level1 > .navigation-title > a:hover {
  text-decoration: underline;
}

.navigation.navigation--sitemap nav .level2 > .navigation-title > a {
  font-size: 1.125em;
  font-weight: 400;
}

.navigation.navigation-main.site-nav,
.navigation.navigation-main.site-nav .component-content,
.navigation.navigation-main.site-nav .component-content > nav {
  position: static;
}

/*
//This is here. But with a backstory:
.container {
	max-width: 100%;
}

In the beginning. SXA labelled all of their components with class container, even if they were full-width.
Instead of adding container-full class to navigation item, this class was added to  navigation to override the widths.
*/
.container {
  max-width: 100%;
}

header {
  position: relative;
  margin: 0 -15px;
  z-index: 10;
}

@media (max-width: 1629px) {
  header {
    font-size: 0.669vw;
  }
}

@media (max-width: 991px) {
  header {
    font-size: 10px;
  }
}

header .container {
  max-width: 100%;
}

header .component {
  margin-top: 0;
}

header .nav-wrapper {
  background-color: #000000;
  z-index: 9;
  transition: top 2s ease, background-color 0.3s ease;
  position: relative;
}

header .nav-wrapper.sticky {
  position: fixed;
  width: calc(100% - 48px);
  top: 0px;
}

@media (max-width: 991px) {
  header .nav-wrapper.sticky {
    width: 100%;
  }
}

@media (max-width: 767px) {
  header .nav-wrapper.homesticky {
    background: #000;
  }
}

header .nav-wrapper.homesticky .logo {
  display: block;
}

header .nav-wrapper .component-content {
  display: flex;
}

@media (max-width: 991px) {
  header .nav-wrapper .component-content {
    display: block;
  }
}

header .nav-wrapper .image {
  flex-basis: 126px;
  padding: 2.1em 0 1.1em 4.6em;
  z-index: 10;
  position: relative;
}

@media (max-width: 991px) {
  header .nav-wrapper .image {
    padding: 2.2em 0 1.4em 29px;
  }
}

@media (max-width: 991px) {
  header .nav-wrapper {
    justify-content: space-between;
    height: 75px;
  }
}

header .nav-wrapper .mirvac-logo {
  padding: 2.2em 0 0 3.7em;
}

header .nav-wrapper .mirvac-logo > .component-content a {
  position: relative;
}

header .nav-wrapper .mirvac-logo > .component-content a:before {
  content: '';
  border-left: 1px solid #fff;
  height: 18px;
  position: absolute;
  left: -37px;
  top: 2px;
  opacity: 0.5;
}

@media (max-width: 991px) {
  header .nav-wrapper .mirvac-logo > .component-content a {
    display: none;
  }
}

header .col-1,
header .col-2,
header .col-3,
header .col-4,
header .col-5,
header .col-6,
header .col-7,
header .col-8,
header .col-9,
header .col-10,
header .col-11,
header .col-12,
header .col,
header .col-auto,
header .col-sm-1,
header .col-sm-2,
header .col-sm-3,
header .col-sm-4,
header .col-sm-5,
header .col-sm-6,
header .col-sm-7,
header .col-sm-8,
header .col-sm-9,
header .col-sm-10,
header .col-sm-11,
header .col-sm-12,
header .col-sm,
header .col-sm-auto,
header .col-md-1,
header .col-md-2,
header .col-md-3,
header .col-md-4,
header .col-md-5,
header .col-md-6,
header .col-md-7,
header .col-md-8,
header .col-md-9,
header .col-md-10,
header .col-md-11,
header .col-md-12,
header .col-md,
header .col-md-auto,
header .col-lg-1,
header .col-lg-2,
header .col-lg-3,
header .col-lg-4,
header .col-lg-5,
header .col-lg-6,
header .col-lg-7,
header .col-lg-8,
header .col-lg-9,
header .col-lg-10,
header .col-lg-11,
header .col-lg-12,
header .col-lg,
header .col-lg-auto,
header .col-xl-1,
header .col-xl-2,
header .col-xl-3,
header .col-xl-4,
header .col-xl-5,
header .col-xl-6,
header .col-xl-7,
header .col-xl-8,
header .col-xl-9,
header .col-xl-10,
header .col-xl-11,
header .col-xl-12,
header .col-xl,
header .col-xl-auto {
  position: static;
}

header .logo img {
  width: 64px;
}

header .mirvac-logo img {
  width: 45px;
}

@media (max-width: 767px) {
  .home .nav-wrapper {
    background: transparent;
  }
}

@media (max-width: 767px) {
  .home .nav-wrapper .logo {
    display: none;
  }
}

header .component.search-box.horizontal {
  display: none;
}

body.nav-is-sticky {
  padding-top: 7.2em;
}

header .component.navigation {
  padding: 1.4em 1.8em 2.5em 0;
}

header .component.navigation.site-nav {
  max-width: 100%;
  flex-basis: 39%;
  flex-grow: 1;
  padding-left: 2.6em;
  padding-right: 0;
}

@media (max-width: 1469px) {
  header .component.navigation.site-nav {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  header .component.navigation.site-nav nav ul li {
    display: none;
  }
}

@media (max-width: 991px) {
  header .component.navigation.site-nav.navigation-main {
    display: none;
  }
}

header .component.navigation.navigation-global {
  max-width: 100%;
  flex-basis: 23%;
  flex-grow: 0;
}

header .component.navigation.navigation-global > .component-content {
  justify-content: flex-end;
  padding-right: 1.1em;
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global {
    position: relative;
    padding: 0;
    margin-top: -52px;
  }
}

header .component.navigation.navigation-global nav ul {
  justify-content: flex-end;
}

header .component.navigation.navigation-global nav ul li {
  border-left: none;
  padding: 0 2.4em;
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global nav ul li {
    display: none;
  }
}

header .component.navigation.navigation-global .search-toggle a {
  display: inline-block;
  text-indent: -9999px;
  background-image: url(../images/icons/icon-nav-search.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.3em;
  height: 1.3em !important;
  margin-top: -0.2em;
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global .search-toggle a {
    background: url(../images/icons/icon-nav-search.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px;
    position: absolute !important;
    top: 0px;
    right: 69px;
    padding: 7px;
  }
}

header .component.navigation.navigation-global .search-toggle.is-open a {
  background-image: url(../images/icons/icon-close--white.svg);
}

header .component.navigation.navigation-global .search-toggle.is-open .component.search-box.horizontal {
  display: inline-block;
}

header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal {
  position: absolute;
  top: 2.8em;
  z-index: 1;
  right: 0;
  z-index: 200;
  background: #f9f9f9;
  font-size: 2em;
  width: 54rem;
  max-width: calc(100vw - 6rem);
}

@media (min-width: 768px) and (max-width: 1199px) {
  header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal {
    top: 3.2em;
  }
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal {
    max-width: 100%;
  }
}

header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal input {
  max-height: 6.3rem;
  padding: 1.1em 1.8em 1.1em;
  border: 0;
  background-color: #f9f9f9 !important;
  color: #55565b;
  font-size: 1em;
  line-height: 1.4em;
  box-shadow: none;
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal input {
    position: absolute;
    left: 0;
    right: 0;
  }
}

header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal button {
  display: block;
  position: absolute;
  top: 50%;
  right: 0.9em;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  transform: translateY(-50%);
  border: 0;
  background: url(../images/icons/icon-nav-search--grey.svg) !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  text-indent: -9999px;
  cursor: pointer;
  appearance: none;
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal {
    position: absolute;
    top: 44px;
    width: 100%;
    right: 0;
    left: 0;
  }
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal label {
    display: none;
  }
}

header .component.navigation.navigation-global .search-toggle .tt-dropdown-menu {
  display: none !important;
}

@media (max-width: 991px) {
  header .component.navigation.navigation-global .search-toggle {
    display: block;
    position: relative;
  }
}

header .component.navigation nav ul {
  margin-top: 1.3em;
}

header .component.navigation nav ul li {
  vertical-align: middle;
  flex-grow: 0;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0 2.4em;
  height: 1.8em;
}

header .component.navigation nav ul li a + a {
  display: none;
}

header .component.navigation nav ul li .meganav-content {
  display: none;
}

header .component.navigation nav ul .is-open > a:after {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: url(../images/icons/icon-close--white.svg);
  font-size: 0.8em;
  margin-left: 1.2em;
  background-repeat: no-repeat;
}

header .component.navigation nav ul .field-navigationimage {
  height: 100%;
  background-size: cover;
  opacity: 0.4;
}

header .component.navigation nav ul .level1 > a,
header .component.navigation nav ul .level1 > .field-navigationtitle > a {
  color: #FFFFFF;
  font-size: 1.6em;
  font-weight: 500;
  line-height: 1;
  vertical-align: bottom;
  display: inline-block;
  height: 25px;
  position: relative;
  white-space: nowrap;
}

header .component.navigation nav ul .level1 > a:before,
header .component.navigation nav ul .level1 > .field-navigationtitle > a:before {
  content: '';
  width: 0%;
  height: 2px;
  background: white;
  position: absolute;
  top: 100%;
  left: 0;
  transition: width 0.5s ease;
}

header .component.navigation nav ul .level1 > a:hover,
header .component.navigation nav ul .level1 > .field-navigationtitle > a:hover {
  text-decoration: none;
}

@media (min-width: 992px) {
  header .component.navigation nav ul .level1 > a:hover:before,
  header .component.navigation nav ul .level1 > .field-navigationtitle > a:hover:before {
    width: 100%;
  }
}

header .component.navigation nav ul .logo-home {
  max-width: 100px;
  border: 0;
  padding: 0;
}

header .component.navigation nav ul .logo-home a {
  padding: 0;
  margin-right: 1.5em;
  text-align: left;
}

header .component.navigation nav ul .logo-home a img {
  max-width: 75px;
  width: 75px;
  margin-top: -0.5em;
}

header .component.navigation nav ul .logo-home a + a {
  display: none;
}

header .component.navigation nav ul .logo-home + li {
  border-left: 1px solid #fff;
}

header .component.navigation nav ul .last a {
  border-right: 0;
}

header .component.navigation nav ul li.is-open > ul {
  display: flex;
}

header .component.navigation nav ul ul {
  margin-top: 0;
}

header .component.navigation.side-nav .mirvac-logo {
  margin-top: -0.5em;
  border: 0;
}

header .component.navigation.side-nav .mirvac-logo a {
  height: 25px;
  width: 45px;
  display: block;
  background: url(../images/icons/logo-mirvac.svg);
  text-indent: -9999px;
  display: inline-block;
}

header .component.navigation.side-nav li:last-of-type {
  padding-right: 0;
  border-right: 0;
}

header .component.navigation.site-nav .dropdown {
  display: none;
  position: absolute;
  right: 0em;
  left: 0em;
  height: calc(100vh - 9.4em);
  top: 100%;
  z-index: 100;
  overflow: hidden;
  padding-bottom: 20px;
  background: #fff;
}

.cookie-message-open header .component.navigation.site-nav .dropdown {
  top: 100%;
  height: calc(100vh - 10.6em);
}

@media (max-height: 800px) {
  header .component.navigation.site-nav .dropdown {
    font-size: 1vh;
  }
}

header .component.navigation.site-nav .dropdown.is-active {
  display: flex;
}

header .component.navigation.site-nav .dropdown > ul > li {
  margin-bottom: 3em;
}

header .component.navigation.site-nav .dropdown > ul > li > a {
  font-size: 3.4em;
  font-family: "Frank Ruhl Libre", serif;
}

header .component.navigation.site-nav .dropdown + ul.clearfix {
  display: none;
}

header .component.navigation.site-nav .dropdown .image-text {
  flex-basis: 32.833333%;
  position: relative;
  background: black;
  flex-grow: 0;
  flex-shrink: 0;
}

header .component.navigation.site-nav .dropdown .image-text .field-navigationtext {
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
}

header .component.navigation.site-nav .dropdown .image-text img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  opacity: 0.4;
}

.no-object-fit header .component.navigation.site-nav .dropdown .image-text img {
  width: auto;
  max-width: inherit;
  min-width: 100%;
}

header .component.navigation.site-nav .dropdown .image-text .content {
  display: flex;
  padding: 2.5em 2.5em;
  text-align: left;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

header .component.navigation.site-nav .dropdown .image-text .content h3 {
  font-family: "Oswald", sans-serif;
  font-size: 2.6em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.5em;
}

header .component.navigation.site-nav .dropdown .image-text .content .btn.btn-link {
  font-size: 16px;
}

header .component.navigation.site-nav .dropdown > ul.clearfix {
  background: white;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  padding: 2.5em 2.5em 2.5em;
  min-width: 350px;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li {
  width: 100%;
  text-align: left;
  height: auto;
  margin-bottom: 3.1vh;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li:hover, header .component.navigation.site-nav .dropdown > ul.clearfix li:focus, header .component.navigation.site-nav .dropdown > ul.clearfix li:active {
  background: none;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li a {
  color: #373737;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li.level2 {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 4.11765em;
  font-weight: 500;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li.level3 {
  font-family: "Roboto", sans-serif;
  font-size: 0.58824em;
  font-weight: 400;
}

@media (max-width: 1600px) {
  header .component.navigation.site-nav .dropdown > ul.clearfix li.level2 {
    font-size: 2.4vw;
  }
}

header .component.navigation.site-nav .dropdown > ul.clearfix ul.clearfix {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

header .component.navigation.site-nav .dropdown > ul.clearfix ul.clearfix li {
  border-right: 1px solid #dbdbdb;
  line-height: 1em;
  width: auto;
}

header .component.navigation.site-nav .dropdown > ul.clearfix ul.clearfix li:first-of-type {
  padding-left: 0;
}

header .component.navigation.site-nav .dropdown > ul.clearfix ul.clearfix li:last-of-type {
  border-right: 0;
}

header .component.navigation.site-nav .dropdown > ul.clearfix ul.clearfix a {
  font-size: 0.8em;
  color: #55565b;
  font-family: "Roboto", sans-serif;
  text-transform: none;
}

header .component.navigation.site-nav .dropdown.animate .image-text,
header .component.navigation.site-nav .dropdown.animate > ul.clearfix {
  transform: translateX(0);
}

header .component.navigation.mmenu-cover {
  background-color: #fff;
  background-image: url("/-/media/Project/Mirvac/OandI/200-George/Icons/logo-200-george-footer.svg?la=en&hash=8FA0F154C3C73EA6AB7C67031866D52C2BCFEFB8");
  background-position: 11px 14px;
  background-repeat: no-repeat;
  background-size: 65px;
}

body.dropdown-open {
  overflow: hidden;
}

.component.navigation nav ul,
.component.link-list nav ul {
  display: flex;
}

@media (max-width: 991px) {
  .component.navigation nav ul,
  .component.link-list nav ul {
    display: block;
  }
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.navigation.navigation-fat {
  background: #FFFFFF;
  border: 1px solid #d2d2d2;
}

.navigation.navigation-fat a {
  text-decoration: none;
}

.navigation.navigation-fat nav > ul {
  list-style: none;
  padding: 10px 0;
  overflow: hidden;
}

.navigation.navigation-fat .rel-level1 {
  margin: 10px;
  padding: 0;
  display: inline-block;
  vertical-align: top;
}

.navigation.navigation-fat .rel-level1.submenu ul a {
  position: relative;
}

.navigation.navigation-fat .rel-level1.submenu ul a:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateY(-50%);
  position: absolute;
  content: "";
  top: 50%;
  left: -9px;
  font-size: 10px;
}

.navigation.navigation-fat .rel-level1 > .navigation-title > a {
  border-top: 2px solid #89C6CC;
  border-bottom: 0;
  background: #f6f6f6;
  padding: 5px 10px;
  display: block;
  width: auto;
}

.navigation.navigation-fat .rel-level1 > .navigation-title > a:hover {
  border-color: #1b809e;
}

.navigation.navigation-fat .rel-level1 > ul {
  padding: 0;
}

.navigation.navigation-fat .rel-level2 {
  padding-left: 10px;
  display: block;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.navigation.navigation-main, .navigation.global-nav {
  position: relative;
}

@media (max-width: 991px) {
  .navigation.navigation-main, .navigation.global-nav {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .navigation.navigation-main a, .navigation.global-nav a {
    padding: 1em;
  }
}

.navigation.navigation-main .component-content, .navigation.global-nav .component-content {
  position: relative;
}

.navigation.navigation-main .component-content > nav, .navigation.global-nav .component-content > nav {
  position: relative;
}

.navigation.navigation-main .rel-level1, .navigation.global-nav .rel-level1 {
  display: inline-block;
}

.navigation.navigation-main .rel-level1.active > span, .navigation.global-nav .rel-level1.active > span {
  display: block;
  padding: 10px 20px;
  color: #fff;
}

.navigation.navigation-main .rel-level1 > .navigation-title > a:hover, .navigation.global-nav .rel-level1 > .navigation-title > a:hover {
  border-color: #89C6CC;
}

.navigation.navigation-main .rel-level1:last-child > .navigation-title > a, .navigation.global-nav .rel-level1:last-child > .navigation-title > a {
  margin-right: 0;
}

.navigation.navigation-main .rel-level1 > ul, .navigation.global-nav .rel-level1 > ul {
  display: none;
  position: absolute;
  top: 100%;
  margin-top: -2px;
  margin-left: 0;
  border-top: 2px solid #89C6CC;
  border-bottom: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
  background: #f6f6f6;
  overflow: hidden;
  z-index: 10;
  padding: 0 calc(2.4em) 0;
}

.navigation.navigation-main .rel-level1.show > a + ul, .navigation.global-nav .rel-level1.show > a + ul {
  display: block !important;
}

.navigation.navigation-main .rel-level1.show > ul, .navigation.global-nav .rel-level1.show > ul {
  display: block !important;
  min-width: 60px;
}

.navigation.navigation-main .rel-level1.active a:before, .navigation.global-nav .rel-level1.active a:before {
  width: 100%;
  border-bottom: 2px solid #fff;
}

.navigation.navigation-main .rel-level2:hover, .navigation.navigation-main .rel-level2:focus, .navigation.global-nav .rel-level2:hover, .navigation.global-nav .rel-level2:focus {
  transition: background 0.2s ease-in;
  background: #f6f6f6;
}

.navigation.navigation-main .rel-level2.submenu, .navigation.global-nav .rel-level2.submenu {
  box-sizing: border-box;
}

.navigation.navigation-main .rel-level2.submenu.navigation-image, .navigation.global-nav .rel-level2.submenu.navigation-image {
  text-align: center;
}

.navigation.navigation-main .rel-level2.submenu.navigation-image > a,
.navigation.navigation-main .rel-level2.submenu.navigation-image .field-navigationtext, .navigation.global-nav .rel-level2.submenu.navigation-image > a,
.navigation.global-nav .rel-level2.submenu.navigation-image .field-navigationtext {
  text-align: left;
}

.navigation.navigation-main.navigation-main-horizontal .rel-level1.show > a + ul, .navigation.global-nav.navigation-main-horizontal .rel-level1.show > a + ul {
  display: inline-flex !important;
  flex-wrap: wrap;
}

.navigation.navigation-main.navigation-main-horizontal .rel-level1.show > ul, .navigation.global-nav.navigation-main-horizontal .rel-level1.show > ul {
  display: inline-flex !important;
  flex-wrap: wrap;
}

.navigation.navigation-main.navigation-main-horizontal .rel-level1 > ul, .navigation.global-nav.navigation-main-horizontal .rel-level1 > ul {
  left: 0;
  width: 100%;
  clear: both;
}

.navigation.navigation-main.navigation-main-horizontal .rel-level1 > ul li, .navigation.global-nav.navigation-main-horizontal .rel-level1 > ul li {
  height: auto;
}

.navigation.navigation-main.navigation-main-vertical .rel-level1 > ul, .navigation.global-nav.navigation-main-vertical .rel-level1 > ul {
  left: auto;
  width: auto;
  padding-left: 0;
  border-right: 1px solid #d2d2d2;
}

.navigation.navigation-main.navigation-main-vertical .rel-level1 > ul > li, .navigation.global-nav.navigation-main-vertical .rel-level1 > ul > li {
  padding: 5px 10px;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.navigation.navigation-mobile nav > ul {
  border: 0;
}

.navigation.navigation-mobile .rel-level1 {
  cursor: pointer;
  margin: 0;
}

.navigation.navigation-mobile .rel-level1 > ul {
  padding-left: 25px;
  display: none;
  background: #f6f6f6;
}

.navigation.navigation-mobile .rel-level1 > ul li {
  position: relative;
}

.navigation.navigation-mobile .rel-level1 > ul li a:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  font-size: 10px;
  top: 20px;
  transform: translateY(-50%);
}

.navigation.navigation-mobile .rel-level1.submenu.active > .navigation-title:before {
  transform: rotate(180deg);
  transition: 0.6s;
  transform-style: preserve-3d;
}

.navigation.navigation-mobile .rel-level1.submenu.active > ul {
  display: block;
}

.navigation.navigation-mobile .rel-level1.submenu > .navigation-title {
  position: relative;
}

.navigation.navigation-mobile .rel-level1.submenu > .navigation-title:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  display: block;
  position: absolute;
  right: 10px;
  font-size: 10px;
  top: 20px;
  transform: translateY(-50%);
  transform: rotate(0);
  transition: 0.6s;
  transform-style: preserve-3d;
}

.navigation.navigation-mobile .rel-level1 > .navigation-title {
  border-bottom: 1px solid #89C6CC;
  margin: 0;
}

.navigation.navigation-mobile .rel-level1 > .navigation-title:last-child {
  border-bottom: none;
}

.navigation.navigation-mobile .rel-level1 .navigation-title {
  padding: 0 30px;
}

.navigation.navigation-mobile .rel-level1 .navigation-title > a {
  text-decoration: none;
  color: #707070;
  display: inline;
}

@media (max-width: 991px) {
  .navigation.navigation-mobile .rel-level1 .navigation-title {
    padding: 0 5px;
  }
}

.navigation.navigation-mobile .rel-level1:last-child .navigation-title {
  padding-right: 0;
}

.navigation.navigation-mobile .rel-level2 {
  display: list-item;
}

.navigation.navigation-mobile .rel-level2 .navigation-title > a {
  font-weight: 500;
}

.navigation.navigation-mobile .rel-level2 ul {
  display: none;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.navigation.navigation-sidebar a {
  text-decoration: none;
}

.navigation.navigation-sidebar nav > ul .rel-level1 > .navigation-title {
  margin-bottom: 0;
}

.navigation.navigation-sidebar nav > ul .rel-level1 > .navigation-title > a {
  padding: 5px 10px;
  display: block;
  position: relative;
  z-index: 1;
  margin-right: 0;
}

.navigation.navigation-sidebar nav > ul .rel-level1 > ul {
  margin-left: 10px;
  margin-top: 5px;
}

.navigation.navigation-sidebar nav > ul .rel-level2 {
  display: block;
}
